import React from 'react'
import Layout from '../layouts'

const NotFoundPage = props => (
  <Layout {...props}>
    <div className="page">
      <h1>404 - NOT FOUND</h1>
      <p>Sorry we couldnt find the page you where looking for.</p>
    </div>
  </Layout>
)

export default NotFoundPage
